import React from "react";

export default class HomeBanner extends React.Component {

    render() {
        return (
            <section className="banner-section index" id="index">
                <div className="overlay">
                    <div className="banner-content">
                        <div className="container wow fadeInUp">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-xl-7 col-lg-6 col-md-8">
                                    <div className="main-content">
                                        <div className="top-area section-text">
                                            <h5 className="sub-title">Chào mừng tới BBOLD Studio</h5>
                                            <h1 className="title">We are young but <em>BOLD</em></h1>
                                            <p className="xlr">Chúng tôi giúp cho hàng triệu người dùng giải trí bằng các trò chơi của mình. Sứ mệnh của chúng tôi là giúp cho toàn thế giới giải trí mà không chỉ là chơi game.</p>
                                            <div className="btn-area mt-30">
                                                <a href="#games" className="cmn-btn">
                                                    <i className="icon-b-right-arrow"></i>
                                                    Khám Phá
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-6 col-md-8">
                                    <div className="sec-img">
                                        <img src="assets/images/index-illus.png" className="max-un" alt="image"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}