import React from "react";
import axios from "axios";
import IMessage from "../types/IMessage";
import {toast, ToastContainer} from "react-toastify";
import ISetting from "../types/ISetting";

const qs = require('qs');

type Props = {};

type State = {
    newMessage: IMessage,
    settings: ISetting
}

export default class HomeContact extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            newMessage: {} as IMessage,
            settings: {} as ISetting
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeMessage = this.handleChangeMessage.bind(this);
    }

    componentDidMount(): void {
        this.getData();
    }

    getData = () => {
        fetch('./assets/data/settings.json')
            .then(res => {
                return res.json();
            })
            .then(result => {
                    this.setState({
                        settings: result
                    })
                }
            );
    };

    handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
        let newMessage = this.state.newMessage;
        newMessage.name = event.target.value;
        this.setState({
            newMessage: newMessage
        });
    }

    handleChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        let newMessage = this.state.newMessage;
        newMessage.email = event.target.value;
        this.setState({
            newMessage: newMessage
        });
    }

    handleChangeSubject(event: React.ChangeEvent<HTMLInputElement>) {
        let newMessage = this.state.newMessage;
        newMessage.subject = event.target.value;
        this.setState({
            newMessage: newMessage
        });
    }

    handleChangeMessage(event: React.ChangeEvent<HTMLTextAreaElement>) {
        let newMessage = this.state.newMessage;
        newMessage.message = event.target.value;
        this.setState({
            newMessage: newMessage
        });
    }

    handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        const {newMessage} = this.state;
        if (newMessage.name == null) {
            this.ShowToast("Name can not be empty!");
            return;
        }
        if (newMessage.email == null) {
            this.ShowToast("Email can not be empty!");
            return;
        }
        if (newMessage.subject == null) {
            this.ShowToast("Subject can not be empty!");
            return;
        }
        if (newMessage.message == null) {
            this.ShowToast("Message can not be empty!");
            return;
        }
        this.sendMessage(newMessage);
    };

    sendMessage = (message: IMessage) => {
        const {settings} = this.state;
        let mess = '🌀🌀🌀<b>Web Message</b>🌀🌀🌀';
        mess += '\r\n<b>Name:</b> ' + message.name;
        mess += '\r\n<b>Email:</b> ' + message.email;
        mess += '\r\n<b>Subject:</b> ' + message.subject;
        mess += '\r\n<b>Message:</b> ' + message.message;
        console.log(mess);
        return axios
            .post("https://api.telegram.org/" + settings.telegram_bot_key + "/sendMessage", {
                chat_id: settings.telegram_chat_id,
                parse_mode: "HTML",
                text: mess
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                console.log(response);
                return response;
            });
    };

    ShowToast = (message: string) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    render() {
        const {newMessage, settings} = this.state;
        return (
            <>
                <section className="contact-us" id="contact">
                    <div className="overlay">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-6">
                                    <div className="section-header text-center">
                                        <h5 className="sub-title">Liên Hệ</h5>
                                        <h2 className="title">Kết nối với chúng tôi</h2>
                                        <p>Gửi email cho chúng tôi hoặc bắt đầu cuộc trò chuyện bằng cách điền vào biểu
                                            mẫu bên dưới.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-lg-5">
                                    <div className="section-text">
                                        <h4>Thông tin liên hệ!</h4>
                                    </div>
                                    {/*<div className="single-box">
                                        <div className="icon-area">
                                            <img src="assets/images/icon/email.png" alt="icon"/>
                                        </div>
                                        <div className="text-area">
                                            <h5>Legal Name</h5>
                                            <p>{settings.company_name}</p>
                                        </div>
                                    </div>*/}
                                    <div className="single-box">
                                        <div className="icon-area">
                                            <img src="assets/images/icon/email.png" alt="icon"/>
                                        </div>
                                        <div className="text-area">
                                            <h5>{settings.company_name}</h5>
                                            <p>{settings.email}</p>
                                        </div>
                                    </div>
                                    <div className="single-box">
                                        <div className="icon-area">
                                            <img src="assets/images/icon/phone.png" alt="icon"/>
                                        </div>
                                        <div className="text-area">
                                            <h5>Số Điện Thoại</h5>
                                            <p>{settings.phone}</p>
                                        </div>
                                    </div>
                                    <div className="single-box">
                                        <div className="icon-area">
                                            <img src="assets/images/icon/location.png" alt="icon"/>
                                        </div>
                                        <div className="text-area">
                                            <h5>Địa Chỉ</h5>
                                            <p>{settings.location}</p>
                                        </div>
                                    </div>
                                    <div className="social">
                                        <h5>Follow us</h5>
                                        <ul className="d-flex mt-2 align-items-center">
                                            <li><a target="_blank" href={settings.fanpage}
                                                   tabIndex={-1}>
                                                <i className="fb fab fa-facebook-f"></i></a></li>
                                            <li><a target="_blank" href={settings.linkedin}
                                                   tabIndex={-1}>
                                                <i className="in fab fa-linkedin-in"></i></a></li>
                                            <li><a target="_blank"
                                                   href={settings.google_play}
                                                   tabIndex={-1}>
                                                <i className="fab fa-google-play"></i></a></li>
                                            <li><a target="_blank"
                                                   href={settings.app_store}
                                                   tabIndex={-1}>
                                                <i className="fab fa-apple"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="col-12">
                                            <div className="single-input">
                                                <label htmlFor="name">Họ Tên</label>
                                                <input type="text" id="name" placeholder="Enter your name"
                                                       value={newMessage.name}
                                                       onChange={this.handleChangeName}
                                                       autoComplete="off"/>
                                            </div>
                                            <div className="single-input">
                                                <label htmlFor="email">Email</label>
                                                <input type="text" id="email" placeholder="Enter your email"
                                                       value={newMessage.email}
                                                       onChange={this.handleChangeEmail}
                                                       autoComplete="off"/>
                                            </div>
                                            <div className="single-input">
                                                <label htmlFor="subject">Tiêu đề</label>
                                                <input type="text" id="subject" placeholder="Enter your subject"
                                                       value={newMessage.subject}
                                                       onChange={this.handleChangeSubject}
                                                       autoComplete="off"/>
                                            </div>
                                            <div className="single-input">
                                                <label htmlFor="message">Lời nhắn</label>
                                                <textarea cols={30} id="message" rows={4}
                                                          value={newMessage.message}
                                                          onChange={this.handleChangeMessage}
                                                          placeholder="Please type your message here..."></textarea>
                                            </div>
                                            <div className="btn-area text-center">
                                                <button>Gửi message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        )
    }
}