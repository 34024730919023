import React from "react";
import ISetting from "../types/ISetting";

type Props = {};

type State = {
    settings: ISetting
}

export default class HomeAboutUs extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            settings: {} as ISetting
        };
    }

    componentDidMount(): void {
        this.getData();
    }

    getData = () => {
        fetch('./assets/data/settings.json')
            .then(res => {
                return res.json();
            })
            .then(result => {
                    this.setState({
                        settings: result
                    })
                }
            );
    };

    render() {
        const {settings} = this.state
        return (
            <>
                <section className="about-us" id="about-us">
                    <div className="overlay pt-120 pb-120">
                        <div className="container wow fadeInUp">
                            <div className="row justify-content-center">
                                <div className="col-lg-10">
                                    <div className="section-header text-center">
                                        <h5 className="sub-title">Imagination, Innovation, Incarnation</h5>
                                        <h2 className="title">Nhiệm vụ của chúng tôi là tạo ra thế giới giải trí tuyệt
                                            vời</h2>
                                        <p>Sứ mệnh của chúng tôi là giải trí thế giới không chỉ là chơi game. Chúng tôi
                                            tạo ra các sản phẩm giải trí và sáng tạo cho cuộc sống hàng ngày.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div
                                        className="magnific-area position-relative d-flex align-items-center justify-content-around">
                                        <div className="bg-area">
                                            <img className="bg-item" src="assets/data/video-bg.png" alt="image"/>
                                        </div>
                                        <div
                                            className="content-area position-absolute d-flex align-items-center justify-content-center">
                                            <div className="content-box">
                                                <a className="mfp-iframe popupvideo d-flex align-items-center justify-content-center"
                                                   href="https://www.youtube.com/watch?v=4XWBd1Jnpkw">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon"/>
                                                </a>
                                                <h2>Gặp BBOLD Studio</h2>
                                                <p>We are young but BOLD</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="counter-section">
                    <div className="overlay pb-120">
                        <div className="container wow fadeInUp">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-6">
                                    <div className="section-area">
                                        <h5 className="sub-title">BBOLD Studio</h5>
                                        <h2 className="title">Một Game Studio trẻ và năng động</h2>
                                        <p>Chúng tôi đam mê chơi game và làm game! Chúng tôi tạo ra các ứng dụng giải
                                            trí và sáng tạo hàng đầu cho cuộc sống hàng ngày.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row cus-mar">
                                        <div className="col-sm-6 mt-60">
                                            <div className="single-box">
                                                <h1><span>{settings.games_launched}</span></h1>
                                                <p>Games launched</p>
                                            </div>
                                            <div className="single-box second">
                                                <h1><span>{settings.MAU}</span></h1>
                                                <p>Monthly active users</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="single-box third">
                                                <h1><span>{settings.downloads}</span></h1>
                                                <p>Downloads</p>
                                            </div>
                                            <div className="single-box fourth">
                                                <h1><span>{settings.members}</span></h1>
                                                <p>Members</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}