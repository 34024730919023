import React from "react";
import IGame from "../types/IGame";
import Slider from "react-slick";

type Props = {};

type State = {
    games: IGame[]
}

export default class HomeGames extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            games: [] as IGame[]
        };
    }

    componentDidMount(): void {
        this.getData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        let slides = document.getElementsByClassName('slick-arrow');
        for (let i = 0; i < slides.length; i++) {
            const slide = slides[i] as HTMLElement;
            slide.style.display = "flex";
        }
    }

    getData = () => {
        fetch('./assets/data/games.json')
            .then(res => {
                return res.json();
            })
            .then(result => {
                    this.setState({
                        games: result
                    })
                }
            );
    };

    ShowGames = () => {
        const {games} = this.state;
        return games.map((game, i) => (
            <div className="single">
                <div className="single-box">
                    <img src={game.img} alt="Image"/>
                    <div className="app-links">
                        <a target="_blank" href={game.android}><img src="assets/images/play-store.png" alt="Icon"/></a>
                        <a target="_blank" href={game.ios}><img src="assets/images/app-store.png" alt="Icon"/></a>
                    </div>
                </div>
            </div>
        ))
    };

    render() {
        var settings = {
            infinite: true,
            autoplay: true,
            centerMode: true,
            centerPadding: '0px',
            focusOnSelect: false,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            swipeToSlide: true,
            draggable: true,
            prevArrow: (<button type='button' className='slick-prev pull-left'>
                <i className="icon-a-left-arrow" aria-hidden='true'></i></button>),
            nextArrow: (<button type='button' className='slick-next pull-right'>
                <i className="icon-b-right-arrow" aria-hidden='true'></i></button>),
            dots: false,
            dotsClass: 'section-dots',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };
        return (
            <section className="game-slider" id="games">
                <div className="overlay pt-120 pb-120">
                    <div className="container">
                        <div className="row wow fadeInUp">
                            <Slider {...settings} className="game-carousel">
                                {this.ShowGames()}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}