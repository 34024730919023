import React from "react";
import IGallery from "../types/IGallery";
import Slider from "react-slick";

type Props = {};

type State = {
    galleries: IGallery[]
}

export default class HomeGallery extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            galleries: [] as IGallery[]
        };
    }

    componentDidMount(): void {
        this.getData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        let slides = document.getElementsByClassName('slick-arrow');
        for (let i = 0; i < slides.length; i++) {
            const slide = slides[i] as HTMLElement;
            slide.style.display = "flex";
        }
    }

    getData = () => {
        fetch('./assets/data/gallery.json')
            .then(res => {
                return res.json();
            })
            .then(result => {
                    this.setState({
                        galleries: result
                    })
                }
            );
    };

    ShowGallery = () => {
        const {galleries} = this.state;
        return galleries.map((img, i) => (
            <div className="single">
                <div className="single-area">
                    <div className="image-area">
                        <img src={img.img} alt="image"/>
                    </div>
                </div>
            </div>
        ))
    };

    render() {
        var settings = {
            infinite: true,
            autoplay: true,
            centerMode: true,
            centerPadding: '0px',
            focusOnSelect: false,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            swipeToSlide: true,
            draggable: true,
            prevArrow: (<button type='button' className='slick-prev pull-left'>
                <i className="icon-a-left-arrow" aria-hidden='true'></i></button>),
            nextArrow: (<button type='button' className='slick-next pull-right'>
                <i className="icon-b-right-arrow" aria-hidden='true'></i></button>),
            dots: false,
            dotsClass: 'section-dots',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };
        return (
            <section className="gallery-slider" id="we">
                <div className="overlay pb-120">
                    <div className="container-fluid">
                        <div className="row cus-mar">
                            <Slider {...settings} className="gallery-carousel">
                                {this.ShowGallery()}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}