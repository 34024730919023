import React from "react";
import ISetting from "../types/ISetting";

type Props = {};

type State = {
    settings: ISetting
}

export default class Footer extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            settings: {} as ISetting
        };
    }

    componentDidMount(): void {
        this.getData();
    }

    getData = () => {
        fetch('./assets/data/settings.json')
            .then(res => {
                return res.json();
            })
            .then(result => {
                    this.setState({
                        settings: result
                    })
                }
            );
    };

    render() {
        const {settings} = this.state
        return (
            <footer className="footer-section">
                <div className="container pt-120">
                    <div className="footer-area pt-120">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="footer-top d-flex align-items-center justify-content-between">
                                    <a href="#">
                                        <img src="assets/images/logo-dark.png" className="logo" alt="logo"
                                             style={{height: 70}}/>
                                    </a>
                                    <div dangerouslySetInnerHTML={ {__html: settings.verify_info} } />
                                    <div className="footer-box">
                                        <ul className="footer-link d-flex align-items-center gap-4">
                                            <li><a href="#games">Sản Phẩm</a></li>
                                            <li><a href="#about-us">Giới Thiệu</a></li>
                                            <li><a href="#jobs">Tuyển Dụng</a></li>
                                            <li><a href="#contact">Liên Hệ</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <div className="row justify-content-between align-items-center">
                                <div
                                    className="col-lg-8 d-flex justify-content-center justify-content-lg-start order-lg-0 order-1">
                                    <div className="copyright">
                                        <p>Copyright © 2022 <a href="#">BBOLD JSC</a>. All rights reserved.</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 d-flex justify-content-center justify-content-lg-end">
                                    <div className="social">
                                        <ul className="footer-link gap-2 d-flex align-items-center">
                                            <li><a target="_blank" href={settings.fanpage}><i
                                                className="fb fab fa-facebook-f"></i></a></li>
                                            <li><a target="_blank" href={settings.linkedin}><i
                                                className="in fab fa-linkedin-in"></i></a></li>
                                            <li><a target="_blank"
                                                   href={settings.google_play}><i
                                                className="fab fa-google-play"></i></a></li>
                                            <li><a target="_blank"
                                                   href={settings.app_store}><i
                                                className="fab fa-apple"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}