import React from "react";

export default class Header extends React.Component {
    render() {
        return (
            <header className="header-section">
                <div className="overlay">
                    <div className="container">
                        <div className="row d-flex header-area">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <a className="navbar-brand" href="/">
                                    <img src="assets/images/logo2.png" className="logo" alt="logo" style={{height: 70}}/>
                                </a>
                                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbar-content">
                                    <i className="fas fa-bars"></i>
                                </button>
                                <div className="collapse navbar-collapse justify-content-end" id="navbar-content">
                                    <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="#index">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#games">Sản Phẩm</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#about-us">Giới Thiệu</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#we">Hoạt Động</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#jobs">Tuyển Dụng</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#contact">Liên Hệ</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

