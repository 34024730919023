import React from "react";
import Slider from "react-slick";
import IJob from "../types/IJob";

type Props = {};

type State = {
    jobs: IJob[],
    curJob: IJob
}

export default class HomeJobs extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            jobs: [] as IJob[],
            curJob: {} as IJob
        };
    }

    componentDidMount(): void {
        this.getData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        let slides = document.getElementsByClassName('slick-arrow');
        for (let i = 0; i < slides.length; i++) {
            const slide = slides[i] as HTMLElement;
            slide.style.display = "flex";
        }
    }

    getData = () => {
        fetch('./assets/data/jobs.json')
            .then(res => {
                return res.json();
            })
            .then(result => {
                    console.log(result.length);
                    this.setState({
                        jobs: result
                    })
                }
            );
    };

    ViewDetailJob = (job: IJob) => {
        this.setState({
            curJob: job
        });
    };

    ShowJobs = () => {
        const {jobs} = this.state;
        return jobs.map((job, i) => (
            <div className="single">
                <div className="single-area">
                    <h4>{job.title}</h4>
                    <div className="info-area d-flex align-items-center">
                        <div className="single-info">
                            <i className="fas fa-calendar-check"></i>
                            <p>{job.experience}</p>
                        </div>
                        <div className="single-info">
                            <i className="far fa-clock"></i>
                            <p>{job.work_time}</p>
                        </div>
                    </div>
                    <p>{job.brief}</p>
                    <button type="button" data-bs-toggle="modal" data-bs-target="#detailsMod"
                            onClick={() => this.ViewDetailJob(job)}>
                        Chi Tiết
                        <i className="icon-d-right-arrow-2"></i>
                    </button>
                </div>
            </div>
        ))
    };

    render() {
        const {curJob} = this.state;
        var settings = {
            infinite: true,
            autoplay: true,
            centerMode: true,
            centerPadding: '0px',
            focusOnSelect: false,
            speed: 1000,
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            swipeToSlide: true,
            draggable: true,
            prevArrow: (<button type='button' className='slick-prev pull-left'>
                <i className="icon-a-left-arrow" aria-hidden='true'></i></button>),
            nextArrow: (<button type='button' className='slick-next pull-right'>
                <i className="icon-b-right-arrow" aria-hidden='true'></i></button>),
            dots: false,
            dotsClass: 'section-dots',
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };
        return (
            <>
                <section className="we-are-hiring" id="jobs">
                    <div className="overlay pb-120">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <div className="section-header text-center">
                                        <h5 className="sub-title">Tuyển Dụng!</h5>
                                        <h2 className="title">Cùng BBOLD chinh phục toàn Thế Giới?</h2>
                                        <p>Chúng tôi yêu những gì mình làm và mong muốn lan tỏa những điều tốt đẹp. Đến với BBOLD Studio để xây dựng sự nghiệp của bạn và tạo ra những trải nghiệm khó quên cho người chơi!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row cus-mar">
                                <Slider {...settings} className="hiring-carousel">
                                    {this.ShowJobs()}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="job-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="modal fade" id="detailsMod">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header justify-content-center">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                            </div>
                                            <div className="top-bar">
                                                <div className="info-area d-flex align-items-center">
                                                    <div className="single-info">
                                                        <i className="fas fa-calendar-check"></i>
                                                        <span>{curJob.experience}</span>
                                                    </div>
                                                    <div className="single-info">
                                                        <i className="far fa-clock"></i>
                                                        <span>{curJob.work_time}</span>
                                                    </div>
                                                </div>
                                                <h2>{curJob.title}</h2>
                                            </div>
                                            <div className="bottom-area">
                                                <h4 className="mt-4">Mô tả công việc</h4>
                                                <div dangerouslySetInnerHTML={ {__html: curJob.responsibilities} } />
                                                <h4 className="mt-4">Yêu cầu công việc</h4>
                                                <div dangerouslySetInnerHTML={ {__html: curJob.requirements} } />
                                                <h4 className="mt-4">Quyền lợi</h4>
                                                <div dangerouslySetInnerHTML={ {__html: curJob.benefit} } />
                                            </div>
                                            <div className="btn-area mt-30">
                                                <a href="mailto:talent@bbold.vn">Ứng Tuyển</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}