import React from "react";
import HomeBanner from "../component/HomeBanner";
import HomeGames from "../component/HomeGames";
import HomeAboutUs from "../component/HomeAboutUs";
import HomeTeam from "../component/HomeTeam";
import HomeGallery from "../component/HomeGallery";
import HomeJobs from "../component/HomeJobs";
import HomeContact from "../component/HomeContact";

export default class Home extends React.Component {

    render() {
        return (
            <>
                <HomeBanner/>
                <HomeGames/>
                <HomeAboutUs/>
                {/*<HomeTeam/>*/}
                <HomeGallery/>
                <HomeJobs/>
                <HomeContact/>
            </>
        )
    }
}